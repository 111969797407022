import React, { Component } from 'react';
import './App.css';
import parseQueryParams from './parseQueryParams';
import GoogleIMA from './GoogleIMA';
const aguid = require('aguid');

class App extends Component {
  render() {
    const queryParams = parseQueryParams(window.location.search);
    const url = queryParams.url;
    if (!url) {
      return <div>Error: <code>url</code> parameter missing.</div>;
    }

    // check if audience macro exists
    if (url.indexOf('__XAID__') > 0) {
      // validate audience identifier
      const audience = Math.floor(Math.random() * Math.floor(5));
      const vastQueryParams = parseQueryParams(url);
      const xuid = vastQueryParams.xuid;
      let uid = xuid;
      if (audience > 0) uid = aguid(xuid + audience);
      const finalUrl = url.replace('__XAID__', uid).replace(xuid, '');
      // console.log(`final vast audience: ${finalUrl}`)
      return <GoogleIMA url={finalUrl} />;
    }
    // console.log(`original vast: ${url}`);
    return <GoogleIMA url={url}/>;
  }
}

export default App;
