import * as React from 'react';
import { loadImaSdk } from '@alugha/ima';
import { sendJIEvent } from './PNJI';

declare global {
  interface Window {
    showAds: () => void;
    requestAds: () => void;
    resumeAds: () => void;
  }
}

interface IProps {
  url: string;
}

const domNotInitializedMessage =
  'DOM not initialized correctly. Please ensure all necessary elements are there.';

function sendErrorEvent(message: string) {
  console.log('Error:', message);
  sendJIEvent('error', message);
}

export default class GoogleIMA extends React.Component<IProps> {
  adContainerRef = React.createRef<HTMLDivElement>();
  videoRef = React.createRef<HTMLVideoElement>();
  adsManager?: google.ima.AdsManager;
  adsLoader?: google.ima.AdsLoader;
  adDisplayContainer?: google.ima.AdDisplayContainer;
  intervalTimer: any;
  playButton: any;

  state = {
    hadImpression: false,
    playing: false,
  };

  async componentDidMount() {
    if (!this.adContainerRef.current || !this.videoRef.current) {
      throw new Error(domNotInitializedMessage);
    }
    try {
      await loadImaSdk();

      this.adDisplayContainer = new google.ima.AdDisplayContainer(
        this.adContainerRef.current,
        this.videoRef.current,
      );
      // Create the ad display container.
      this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);
      // Listen and respond to ads loaded and error events.
      this.adsLoader.addEventListener(
        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        this.onAdsManagerLoaded,
        false,
      );
      this.adsLoader.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        this.onAdError,
        false,
      );

      this.requestAds();
    } catch (e) {
      sendErrorEvent('SDK could not be loaded. Check your ad blocker!');
    }

    window.showAds = () => this.playAds();
    window.requestAds = () => this.requestAds();
    window.resumeAds = () => this.resumeAds();
  }

  componentWillUnmount() {
    delete window.showAds;
    delete window.requestAds;
    delete window.resumeAds;
  }

  componentDidCatch(error: any) {
    sendErrorEvent(`GoogleIMA component did catch: ${error}`);
  }

  render() {
    return (
      <>
        <video ref={this.videoRef} onEnded={this.onContentEnded} playsInline />
        <div ref={this.adContainerRef} className="adContainer" />
        {!this.state.playing && <button
          id="playButton"
          className={this.state.playing ? 'playing' : ''}
          onClick={this.playAds}
        >
          <svg width="80px" height="80px" viewBox="0 0 126 126" version="1.1">
            <polygon id="Triangle" fill="#FFFFFF" points="126 63 0 126 0 0" />
          </svg>
        </button>}
      </>
    );
  }

  requestAds() {
    if (!this.adsLoader) {
      throw new Error('`adsLoader` must be initialized before requesting ads.');
    }
    const adsRequest = new google.ima.AdsRequest();
    adsRequest.adTagUrl = this.props.url;
    // Specify the linear and nonlinear slot sizes. This helps the SDK to
    // select the correct creative if multiple are returned.
    adsRequest.linearAdSlotWidth = window.innerWidth; // or 640
    adsRequest.linearAdSlotHeight = window.innerHeight; // or 400
    adsRequest.nonLinearAdSlotWidth = 640;
    adsRequest.nonLinearAdSlotHeight = 150;
    this.adsLoader.requestAds(adsRequest);
  }

  // An event listener to tell the SDK that our content video
  // is completed so the SDK can play any post-roll ads.
  onContentEnded = () => {
    if (this.adsLoader) {
      this.adsLoader.contentComplete();
    }
  }

  playAds = () => {
    if (!this.videoRef.current) {
      throw new Error(domNotInitializedMessage);
    }
    if (!this.adDisplayContainer) {
      throw new Error('AdDisplayContainer must be initialized correctly before playing.');
    }
    if (!this.adsManager) {
      throw new Error('AdsManager must be initialized correctly before playing.');
    }
    // Initialize the container. Must be done via a user action on mobile devices.
    this.videoRef.current.load();
    this.adDisplayContainer.initialize();

    try {
      // Initialize the ads manager. Ad rules playlist will start at this time.
      this.adsManager.init(
        window.innerWidth,
        window.innerHeight,
        google.ima.ViewMode.FULLSCREEN,
      );
      // Call play to start showing the ad. Single video and overlay ads will
      // start at this time; the call will be ignored for ad rules.
      this.adsManager.start();
      this.setState({ playing: true });
      window.addEventListener('resize', () => {
        if (this.adsManager) {
          this.adsManager.resize(
            window.innerWidth,
            window.innerHeight,
            google.ima.ViewMode.FULLSCREEN,
          );
        }
      });
    } catch (adError) {
      // An error may be thrown if there was a problem with the VAST response.
      sendErrorEvent(`Ad error: ${adError}`);
    }
  }

  resumeAds() {
    if (this.adsManager) {
      this.adsManager.resume();
    }
  }

  onImpression() {
    sendJIEvent('impression');
  }

  onAdEvent = (adEvent: google.ima.AdEvent) => {
    if (!this.videoRef.current) {
      throw new Error(domNotInitializedMessage);
    }
    // Retrieve the ad from the event. Some events (e.g. ALL_ADS_COMPLETED)
    // don't have ad object associated.
    const ad = adEvent.getAd();
    if (!ad) {
      throw new Error('Got an error, but no ad was defined.');
    }
    switch ((adEvent as any).type as google.ima.AdEvent.Type) {
      case google.ima.AdEvent.Type.LOADED:
        // This is the first event sent for an ad - it is possible to
        // determine whether the ad is a video ad or an overlay.
        if (!ad.isLinear()) {
          // Position AdDisplayContainer correctly for overlay.
          // Use ad.width and ad.height.
          this.videoRef.current.play();
        }
        break;
      case google.ima.AdEvent.Type.STARTED:
        // This event indicates the ad has started - the video player
        // can adjust the UI, for example display a pause button and
        // remaining time.
        if (ad.isLinear()) {
          // For a linear ad, a timer can be started to poll for
          // the remaining time.
          this.intervalTimer = setInterval(
            () => {
              if (!this.adsManager) {
                return;
              }
              this.setState({
                remainingTime: this.adsManager.getRemainingTime(),
              });
            },
            300,
          );
        }
        break;
      case google.ima.AdEvent.Type.COMPLETE:
        // This event indicates the ad has finished - the video player
        // can perform appropriate UI actions, such as removing the timer for
        // remaining time detection.
        if (ad.isLinear()) {
          clearInterval(this.intervalTimer);
        }
        break;
    }
  }

  onAdError = (adErrorEvent: google.ima.AdErrorEvent) => {
    // Handle the error logging.
    const error: google.ima.AdError = adErrorEvent.getError();
    const isPassback =
      error.getErrorCode() === google.ima.AdError.ErrorCode.VAST_EMPTY_RESPONSE;
    if (isPassback) {
      console.log('Ad passback:', error);
      sendJIEvent('passback');
    } else {
      console.log('yoooo');

      sendErrorEvent(`Ad error: ${error}`);
    }
    if (this.adsManager) {
      this.adsManager.destroy();
    }
  }

  onComplete = () => {
    sendJIEvent('complete');
  }

  onContentPauseRequested = () => {
    console.log('onContentPauseRequested');
  }

  onContentResumeRequested = () => {
    console.log('onContentResumeRequested');
  }

  onAdClick = () => {
    console.log('onAdClick');
    sendJIEvent('clicked');
  }

  onAdsManagerLoaded = (
    adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent,
  ) => {
    if (!this.videoRef.current) {
      throw new Error(domNotInitializedMessage);
    }

    const adsRenderingSettings = new google.ima.AdsRenderingSettings();
    adsRenderingSettings.enablePreloading = true;
    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

    this.adsManager = adsManagerLoadedEvent.getAdsManager(
      this.videoRef.current,
      adsRenderingSettings,
    );

    // Add listeners to the required events.
    this.adsManager.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
    );
    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
      this.onContentPauseRequested,
    );
    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      this.onContentResumeRequested,
    );
    this.adsManager.addEventListener(
        google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        this.onComplete);
    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.IMPRESSION,
      this.onImpression,
    );
    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CLICK,
      this.onAdClick,
    );

    const eventNames = [
      google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      google.ima.AdEvent.Type.LOADED,
      google.ima.AdEvent.Type.STARTED,
      google.ima.AdEvent.Type.COMPLETE,
    ];
    for (const eventName of eventNames) {
      this.adsManager.addEventListener(eventName, this.onAdEvent);
    }
    sendJIEvent('ready');
  }
}
