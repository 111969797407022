// Interface to the external app that shows our deployed React app in a webview.

declare global {
    interface Window {
      webkit?: any;
      PNJI?: any;
      KwizzadJI?: any;
    }
}

export function sendPNJIWebKitMessage(eventName: string, ...args: any[]) {
  try {
    const message = {
      arguments: args,
      event: eventName,
    };
    if (
            typeof window.webkit === 'object' &&
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.PNJI
        ) {
      window.webkit.messageHandlers.PNJI.postMessage(message);
    }
  } catch (error) {
        // tslint:disable-next-line:no-console
    console.log('Error while posting message', error);
  }
}

// Sends a given event name + args to the native app / SDK that integrates Komet.

export function sendJIEvent(eventName: string, ...args: any[]) {
  sendPNJIEvent(eventName, ...args);
    // support legacy JS interface until older SDK versions are no longer in use.
  sendKWJIEvent(eventName, ...args);
}

function sendPNJIEvent(eventName: string, ...args: any[]) {
  if (typeof window.PNJI === 'object') {
    if (typeof window.PNJI[eventName] === 'function') {
            // tslint:disable-next-line:no-console
      console.log(
                `Sending '${eventName}' event to PNJI API: ${JSON.stringify(
                    args,
                )}`,
            );
      try {
        if (args && args.length > 0) {
          window.PNJI[eventName](...args); // eslint-disable-line no-undef
        } else {
          window.PNJI[eventName](); // eslint-disable-line no-undef
        }
      } catch (error) {
                // tslint:disable-next-line:no-console
        console.log(
                    `Could not send '${eventName}' event to JI API: ${String(error)}`,
                );
      }
    } else {
            // tslint:disable-next-line:no-console
      console.log(
                `Ignored '${eventName}' event. JI API is initialized, but does not support this event.`,
            );
    }
  } else {
        // tslint:disable-next-line:no-console
    console.log(`Ignored '${eventName}' event, JI API not available.`);
  }

  sendPNJIWebKitMessage(eventName, ...args);
}

function sendKWJIEvent(eventName: string, ...args: any[]) {
  if (typeof window.KwizzadJI === 'object') {
    if (typeof window.KwizzadJI[eventName] === 'function') {
            // tslint:disable-next-line:no-console
      console.log(
                `Sending '${eventName}' event to KwizzadJI API: ${JSON.stringify(
                    args,
                )}`,
            );
      try {
        if (args && args.length > 0) {
          window.KwizzadJI[eventName](...args); // eslint-disable-line no-undef
        } else {
          window.KwizzadJI[eventName](); // eslint-disable-line no-undef
        }
      } catch (error) {
                // tslint:disable-next-line:no-console
        console.log(
                    `Could not send '${eventName}' event to JI API: ${String(error)}`,
                );
      }
    } else {
            // tslint:disable-next-line:no-console
      console.log(
                `Ignored '${eventName}' event. JI API is initialized, but does not support this event.`,
            );
    }
  } else {
        // tslint:disable-next-line:no-console
    console.log(`Ignored '${eventName}' event, JI API not available.`);
  }

  sendKWJIWebKitMessage(eventName, ...args);
}

export function sendKWJIWebKitMessage(eventName: string, ...args: any[]) {
  try {
    const message = {
      arguments: args,
      event: eventName,
    };
    if (
            typeof window.webkit === 'object' &&
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.KwizzadJI
        ) {
      window.webkit.messageHandlers.KwizzadJI.postMessage(message);
    }
  } catch (error) {
        // tslint:disable-next-line:no-console
    console.log('Error while posting message', error);
  }
}
